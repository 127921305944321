import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const ZieloneDrzwi = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>Wojciech Koszczyński</h1>
          <h2>Introduction</h2>
          <p>
            I was tasked with designing a personal page for a detective. I tried
            to keep the page in dark colors and contrasting blue. The site has
            also received a blog with regular new posts. Personally, I think
            that the logo should be smaller, but it's the client's decision.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/wojciech_koszczynski.png" as="div" />
          <h2>Result</h2>
          <StaticImage
            src="./images/screenshot_wojciech_koszczynski.png"
            as="div"
          />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default ZieloneDrzwi;

export function Head() {
  return <title>Wojciech Koszczyński</title>;
}
